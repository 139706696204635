/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}
/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[3456789]\d{9}$/.test(s)
}
/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}
/* 合法uri */
export function validateURL (textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母 */
export function validateLowerCase (str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母 */
export function validateUpperCase (str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母 */
export function validatAlphabets (str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
/**
 * 校验 email
 * @param email
 * @returns {boolean}
 */
export function validateEmail (email) {
  const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
/**
 * 判断手机号码是否正确
 */
export function isValDateMobile (phone) {
  const list = []
  let result = true
  let msg = ''
  // 增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]
  if (!valDateNull(phone)) {
    if (phone.length === 11) {
      if (!isMobile(phone)) {
        msg = '手机号码格式不正确'
      } else {
        result = false
      }
    } else {
      msg = '手机号码长度不为11位'
    }
  } else {
    msg = '手机号码不能为空'
  }
  list.push(result)
  list.push(msg)
  return list
}

/**
 * 判断身份证号码
 */
export function cardid (code) {
  const list = []
  let result = true
  let msg = ''
  var city = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江 ',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北 ',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏 ',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外 '
  }
  if (!valDateNull(code)) {
    if (code.length === 18) {
      if (!code || !/(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(code)) {
        msg = '证件号码格式错误'
      } else if (!city[code.substr(0, 2)]) {
        msg = '地址编码错误'
      } else {
        // 18位身份证需要验证最后一位校验位
        code = code.split('')
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        // 校验位
        var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2, 'x']
        var sum = 0
        var ai = 0
        var wi = 0
        for (var i = 0; i < 17; i++) {
          ai = code[i]
          wi = factor[i]
          sum += ai * wi
        }
        if (parity[sum % 11] !== code[17]) {
          msg = '证件号码校验位错误'
        } else {
          result = false
        }
      }
    } else {
      msg = '证件号码长度不为18位'
    }
  } else {
    msg = '证件号码不能为空'
  }
  list.push(result)
  list.push(msg)
  return list
}

/**
 * 判断是否为空
 */
export function valDateNull (val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}
// 手机号验证
export function isvalidPhone (str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  return reg.test(str)
}

// 验证密码   密码，以字母开头，长度在6~18之间，只能包含字母、数字和下划线
export function isvalidPass (str) {
  const reg = /^[a-zA-Z]\w{6,18}$/
  return reg.test(str)
}

//   验证用户名  用户名要求 数字、字母、下划线的组合，其中数字和字母必须同时存在*
export function isvalidUsername (str) {
  const reg = /^(?![^A-Za-z]+$)(?![^0-9]+$)[0-9A-Za-z_]{4,15}$/
  return reg.test(str)
}

// 判断是否为正整数
export function isPositiveNum (s) { // 是否为正整数
  const reg = /^[0-9]\d*$/
  return reg.test(s)
}

// 判断是否为整数且可保留两位
export function isFixedNum (num) {
  const reg = /^\d+(.\d{1,2})?$/
  return reg.test(num)
}

// 验证是否整数,非必填
export function isIntegerNotMust (rule, value, callback) {
  if (!value) {
    callback()
  }
  setTimeout(() => {
    if (!Number(value)) {
      callback(new Error('请输入正整数'))
    } else {
      const re = /^[0-9]*[1-9][0-9]*$/
      const rsCheck = re.test(value)
      if (!rsCheck) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    }
  }, 1000)
}
// 密码校验
export const validatePsdReg = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入密码'))
  }
  if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,20}$/.test(value)) {
    callback(new Error('请输入6-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
  } else {
    callback()
  }
}

// 时间 2021-05-10
export function getTodayDate () {
  let yy = new Date().getFullYear()
  let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
  let dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
  return yy + '-' + mm + '-' + dd
}

/**
 * 根据当前日期获取其前七天的日期
 * @param month
 * @return
 */
export function getRecentDay() {
  let arr = []
  for(let i = 6;i >= 0;i --) {
    let temp = getDay(-i)
    arr.push(temp)
  }
  return arr[0]
}
function getDay(day){
  let today = new Date()
  let targetDay = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetDay)
  let tYear = today.getFullYear()
  let tMonth = today.getMonth()
  let tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
}
function doHandleMonth(month){
  let m = month
  if (month.toString().length === 1) {
    m = "0" + month
  }
  return m
}

// 2020-05-10转换5月10号
export function formats (t) {
  var m = t.substring(5, 7)
  var mm = m < 10 ? m.substring(1) : m
  var d = t.substring(8, 10)
  var dd = d < 10 ? d.substring(1) : d
  return mm + '月' + dd + '号'
}

// 获取当前的时间
export function nowTime () {
  var _date = new Date()
  var y = _date.getFullYear()
  var m = zeroChange(_date.getMonth() + 1)
  var d = zeroChange(_date.getDate())
  var h = zeroChange(_date.getHours())
  var mt = zeroChange(_date.getMinutes())
  var s = zeroChange(_date.getSeconds())
  return y + '-' + m + '-' + d + ' ' + h + ':' + mt + ':' + s
}
export function zeroChange (zero) {
  return zero < 10 ? '0' + zero : zero
}

// 转换当前时间
export function getNowTime (t) {
  const _date = new Date(t)
  const y = _date.getFullYear()
  const m = zeroChange(_date.getMonth() + 1)
  const d = zeroChange(_date.getDate())
  const h = zeroChange(_date.getHours())
  const mt = zeroChange(_date.getMinutes())
  const s = zeroChange(_date.getSeconds())
  return y + '-' + m + '-' + d + ' ' + h + ':' + mt + ':' + s
}
